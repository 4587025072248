import { Outlet } from 'react-router-dom'
import { Icon } from '@iconify/react/offline'
import iconLocation from '@iconify/icons-material-symbols/location-on'
import iconSolarPower from '@iconify/icons-material-symbols/solar-power'
import { IconifyIcon } from '@iconify/react'
import React, { PropsWithChildren } from 'react'
import { formatNumberKw } from '@/helpers/NumberFormats.ts'
import {
  AssetPerformanceTabRoute,
  AssetServiceActivityTabRoute,
} from './AssetDetailRoutes.tsx'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { NavTab, NavTabs } from '@/components/core/nav/NavTabs.tsx'

import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'

export function AssetDetailPage(): React.JSX.Element {
  return (
    <div className="tw-flex tw-flex-row tw-overflow-y-hidden tw-bg-white tw-px-20">
      <div className="tw-w-[360px] tw-flex-none tw-overflow-y-auto">
        <AssetMetadataSection />
      </div>
      <div className="tw-h-page tw-w-full tw-overflow-y-auto tw-border-l tw-border-slate-200 tw-p-6">
        <div className="tw-mb-6">
          <AssetDetailNavTabs />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

function AssetDetailNavTabs(): React.JSX.Element {
  const { assetPerformanceEnabled } = useFeatureFlags()
  return (
    <NavTabs>
      {assetPerformanceEnabled && (
        <NavTab title="Performance" path={AssetPerformanceTabRoute} />
      )}
      <NavTab title="Service Activity" path={AssetServiceActivityTabRoute} />
    </NavTabs>
  )
}

function AssetMetadataSection() {
  const { assetId } = useAssetDetailContext()
  const data = useAssetMetadataQuery(assetId).data
  if (!data) return <></>
  return (
    <div className="tw-flex tw-flex-col tw-gap-4 tw-py-8 tw-pr-6">
      <div className="tw-text-xl tw-font-bold tw-text-gray-900">
        {data.assetName}
      </div>
      <AssetMetadataBlock title="Site Address" icon={iconLocation}>
        <div className="tw-text-slate-700">
          {data.address.street}, {data.address.city}, {data.address.state}{' '}
          {data.address.postalCode}
        </div>
      </AssetMetadataBlock>
      {data.systemSize !== null && (
        <AssetMetadataBlock title="System Size" icon={iconSolarPower}>
          <div className="tw-text-slate-700">
            {formatNumberKw(data.systemSize)}
          </div>
        </AssetMetadataBlock>
      )}
    </div>
  )
}

interface AssetMetadataBlockProps {
  title: string
  icon: IconifyIcon
}

function AssetMetadataBlock({
  title,
  icon,
  children,
}: Readonly<PropsWithChildren<AssetMetadataBlockProps>>) {
  return (
    <div className="tw-inline-flex tw-gap-4">
      <Icon className="tw-size-5" icon={icon} />
      <div className="tw-grow">
        <div className="tw-mb-0.5 tw-text-sm tw-text-slate-500">{title}</div>
        {children}
      </div>
    </div>
  )
}
