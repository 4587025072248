import { Icon } from '@iconify/react/offline'
import iconDoubleArrowLeft from '@iconify/icons-material-symbols/keyboard-double-arrow-left-rounded'
import iconArrowLeft from '@iconify/icons-material-symbols/chevron-left-rounded'
import iconArrowRight from '@iconify/icons-material-symbols/chevron-right-rounded'
import iconDoubleArrowRight from '@iconify/icons-material-symbols/keyboard-double-arrow-right-rounded'
import { ComponentProps, PropsWithChildren } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

interface PagerProps {
  maxPageIndexButtons?: number
  pageSize: number
  itemCount: number
  currentPageIndex: number
  onSelectedPageIndexChanged: (selectedIndex: number) => void
  showLimitPageButtons?: boolean
}

export default function Pager(props: Readonly<PagerProps>) {
  const {
    maxPageIndexButtons = 5,
    pageSize,
    itemCount,
    currentPageIndex,
    onSelectedPageIndexChanged,
    showLimitPageButtons = true,
  } = props

  const pageCount = Math.ceil(itemCount / pageSize)
  if (pageCount <= 1) return <></>

  return (
    <ul className="tw-m-0 tw-inline-flex tw-select-none tw-items-center tw--space-x-px tw-p-0">
      {showLimitPageButtons && (
        <PagerButton
          onClick={() => {
            onSelectedPageIndexChanged(0)
          }}
          disabled={currentPageIndex < 2}
          className="tw-rounded-l-lg"
        >
          <Icon icon={iconDoubleArrowLeft} width={24} height={24} />
        </PagerButton>
      )}
      <PagerButton
        onClick={() => {
          onSelectedPageIndexChanged(currentPageIndex - 1)
        }}
        disabled={currentPageIndex === 0}
        className={showLimitPageButtons ? '' : 'tw-rounded-l-lg'}
      >
        <Icon icon={iconArrowLeft} width={24} height={24} />
      </PagerButton>
      {pageNumbers(currentPageIndex, pageCount, maxPageIndexButtons).map(
        pageNumber => (
          <PagerButton
            key={pageNumber}
            className="tw-min-h-[38px] tw-min-w-[48px] tw-px-3 tw-py-2 disabled:tw-bg-blue-200 disabled:tw-font-bold disabled:tw-text-blue-800"
            onClick={() => {
              onSelectedPageIndexChanged(pageNumber - 1)
            }}
            disabled={currentPageIndex === pageNumber - 1}
          >
            {pageNumber}
          </PagerButton>
        )
      )}
      <PagerButton
        onClick={() => {
          onSelectedPageIndexChanged(currentPageIndex + 1)
        }}
        disabled={itemCount === 0 || currentPageIndex === pageCount - 1}
        className={showLimitPageButtons ? '' : 'tw-rounded-r-lg'}
      >
        <Icon icon={iconArrowRight} width={24} height={24} />
      </PagerButton>
      {showLimitPageButtons && (
        <PagerButton
          onClick={() => {
            onSelectedPageIndexChanged(pageCount - 1)
          }}
          disabled={currentPageIndex > pageCount - 3}
          className="tw-rounded-r-lg"
        >
          <Icon icon={iconDoubleArrowRight} width={24} height={24} />
        </PagerButton>
      )}
    </ul>
  )
}

function pageNumbers(
  currentPageIndex: number,
  pageCount: number,
  maxPageNumbers: number
) {
  const offset = Math.floor(maxPageNumbers / 2)
  let start = Math.max(currentPageIndex + 1 - offset, 1)
  const end = Math.min(start + maxPageNumbers - 1, pageCount)
  if (end - start + 1 < maxPageNumbers) {
    start = Math.max(end - maxPageNumbers + 1, 1)
  }
  return Array.from({ length: end - start + 1 }, (_, i) => start + i)
}

function PagerButton({
  children,
  className,
  ...props
}: Readonly<PropsWithChildren<ComponentProps<'button'>>>) {
  const buttonStyle = twMerge(
    'tw-ml-0 tw-block tw-cursor-pointer tw-border tw-border-solid tw-border-gray-300 tw-bg-white tw-px-3 tw-py-1.5 tw-leading-tight tw-text-gray-500 hover:tw-bg-gray-100 hover:tw-text-gray-700 disabled:tw-bg-white disabled:tw-text-gray-300',
    className
  )
  return (
    <li className="tw-list-none">
      <button className={buttonStyle} {...props}>
        {children}
      </button>
    </li>
  )
}
