import { formatNumber, formatNumberToString } from '@/helpers/NumberFormats.ts'

export interface PageStatusProps {
  pageSize: number
  itemCount: number
  currentPageIndex: number
}

export default function PagerStatus({
  pageSize,
  itemCount,
  currentPageIndex,
}: Readonly<PageStatusProps>) {
  const startRow = itemCount > 0 ? currentPageIndex * pageSize + 1 : 0
  const endRow =
    itemCount > 0 ? Math.min(startRow + pageSize - 1, itemCount) : 0

  return (
    <span className="tw-whitespace-nowrap tw-text-sm">
      Showing{' '}
      <strong>{`${formatNumberToString(startRow)} - ${formatNumberToString(endRow)}`}</strong>{' '}
      of <strong>{formatNumber(itemCount)}</strong>
    </span>
  )
}
