import moment from 'moment/moment'
import { ISO_DATE_FORMAT, parseIsoDate } from '@/helpers/MomentHelpers.ts'
import { DateRangePreset } from './DateRangeSelector.tsx'

export function yesterdayPreset(isoRelativeToDate?: string) {
  const today = moment().startOf('day')
  const relDate = parseIsoDate(isoRelativeToDate ?? null) ?? today
  return {
    name: `Yesterday`,
    isoStartDate: moment(relDate)
      .startOf('day')
      .subtract(1, 'day')
      .format(ISO_DATE_FORMAT),
    isoEndDate: moment(relDate)
      .startOf('day')
      .subtract(1, 'day')
      .format(ISO_DATE_FORMAT),
  }
}

type DatePresetUnit = 'days' | 'months' | 'years'
type DatePresetDirection = 'past' | 'future'
type DatePresetOptions = {
  isoRelativeToDate?: string
  includeEndDate?: boolean
}

export function lastNDaysPreset(
  days: number,
  options?: DatePresetOptions
): DateRangePreset {
  return {
    ...createRelativeDateRange(days, 'days', 'past', options),
    name: days === 1 ? 'Last day' : `Last ${days.toString()} days`,
  }
}

export function nextDayPreset(): DateRangePreset {
  const today = moment().startOf('day')
  return {
    name: `Next 24 hours`,
    isoStartDate: moment(today).startOf('day').format(ISO_DATE_FORMAT),
    isoEndDate: moment(today)
      .startOf('day')
      .add(1, 'day')
      .format(ISO_DATE_FORMAT),
  }
}

export function nextNDaysPreset(
  days: number,
  options?: DatePresetOptions
): DateRangePreset {
  return {
    ...createRelativeDateRange(days, 'days', 'future', options),
    name: days === 1 ? 'Next day' : `Next ${days.toString()} days`,
  }
}

export function lastNMonthsPreset(
  months: number,
  options?: DatePresetOptions
): DateRangePreset {
  return {
    ...createRelativeDateRange(months, 'months', 'past', options),
    name: months === 1 ? 'Last month' : `Last ${months.toString()} months`,
  }
}

export function lastNYearsPreset(
  years: number,
  options?: DatePresetOptions
): DateRangePreset {
  return {
    ...createRelativeDateRange(years, 'years', 'past', options),
    name: years === 1 ? 'Last year' : `Last ${years.toString()} years`,
  }
}

export function yearToDatePreset(options?: DatePresetOptions): DateRangePreset {
  const today = moment().startOf('day')
  const relDate = parseIsoDate(options?.isoRelativeToDate ?? null) ?? today
  const startDate = moment(relDate).startOf('year')
  let endDate = moment(relDate).startOf('day')
  if (!options?.includeEndDate) {
    endDate = endDate.subtract(1, 'day')
  }
  return {
    name: 'YTD',
    isoStartDate: startDate.format(ISO_DATE_FORMAT),
    isoEndDate: endDate.format(ISO_DATE_FORMAT),
  }
}

function createRelativeDateRange(
  amount: number,
  unit: DatePresetUnit,
  direction: DatePresetDirection,
  options?: DatePresetOptions
): Omit<DateRangePreset, 'name'> {
  const today = moment().startOf('day')
  const relDate = parseIsoDate(options?.isoRelativeToDate ?? null) ?? today
  let startDate, endDate
  if (direction === 'past') {
    startDate = moment(relDate).startOf('day').subtract(amount, unit)
    endDate = moment(relDate)
  } else {
    startDate = moment(relDate)
    endDate = moment(relDate).add(amount, unit)
  }
  if (!options?.includeEndDate) {
    endDate = endDate.subtract(1, 'day')
  }
  return {
    isoStartDate: startDate.format(ISO_DATE_FORMAT),
    isoEndDate: endDate.format(ISO_DATE_FORMAT),
  }
}
