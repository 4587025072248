/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeysMatching, Maybe } from '@/helpers/TypeHelpers.ts'

// Most aggregation functions are computed given a single column key
// and an array of rows. For aggregations that depend on multiple
// column keys such as weighted average currying is used to "collect"
// the additional arguemnts and produce a function that matches the
// common aggregation function type

export type AggregationFn<T extends Record<string, unknown>> = (
  columnId: KeysMatching<T, Maybe<any>>,
  rows: T[]
) => number | undefined

function sum<T extends Record<string, any>>(
  column: KeysMatching<T, Maybe<any>>,
  rows: T[]
) {
  let sum = 0
  for (const row of rows) {
    sum += (row[column] as Maybe<number>) ?? 0
  }
  return sum
}

function average<T extends Record<string, any>>(
  column: KeysMatching<T, Maybe<any>>,
  rows: T[]
) {
  let count = 0
  let sum = 0
  for (const row of rows) {
    const value = row[column] as number
    if (value) {
      count++
      sum += value
    }
  }
  if (count) return sum / count
}

function weightedAverage<T extends Record<string, any>>(
  weightColumn: KeysMatching<T, Maybe<any>>
): AggregationFn<T> {
  return (column, rows) => {
    let numerator = 0
    let denominator = 0

    for (const row of rows) {
      const weight = row[weightColumn]
      const value = row[column]
      if (weight != null && value != null) {
        numerator += weight * value
        denominator += weight as number
      }
    }

    if (denominator) return numerator / denominator
  }
}

export const Aggregations = {
  sum,
  average,
  weightedAverage,
}
