/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  autoPlacement,
  autoUpdate,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'
import iconError from '@iconify/icons-material-symbols/error-outline'
import iconSortAsc from '@iconify/icons-material-symbols/expand-less'
import iconSortDesc from '@iconify/icons-material-symbols/expand-more'
import iconFilterFilled from '@iconify/icons-material-symbols/filter-alt'
import iconFilterOutline from '@iconify/icons-material-symbols/filter-alt-outline'
import iconSearch from '@iconify/icons-material-symbols/search'
import iconSortNone from '@iconify/icons-material-symbols/unfold-more'
import { IconifyIcon } from '@iconify/react'
import { Icon } from '@iconify/react/offline'
import { Cell, Column, flexRender, Header, Row } from '@tanstack/react-table'
import React, { useState } from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import HeaderFacetFilter from './HeaderFacetFilter.tsx'
import SearchFilter from './SearchFilter.tsx'
import { SimpleTable } from './SimpleTable.tsx'
import { TableStyles } from './TableStyles.ts'
import { TableModel } from './useTable.tsx'
import { TotalsRowModel } from './useTotals.tsx'

export interface TableProps<T extends Record<string, any>> {
  model: TableModel<T>
  totals?: TotalsRowModel<T>
  className?: string
  loading?: boolean
  loadingMessage?: React.ReactNode
  noDataMessage?: React.ReactNode
  error?: boolean
  errorMessage?: React.ReactNode
  renderExpandedRow?: (props: { row: Row<T> }) => React.ReactElement
  onRowClick?: (row: Row<T>) => void
  rowClassName?: (row: Row<T>) => string | undefined
  stickyHeaders?: boolean
}

const defaultHeaderClass: string =
  'tw-px-4 tw-py-3 tw-select-none tw-bg-gray-50 tw-align-text-top'

export default function Table<T extends Record<string, any>>({
  className,
  model,
  totals,
  loading,
  loadingMessage,
  noDataMessage,
  error,
  errorMessage,
  renderExpandedRow,
  onRowClick,
  rowClassName,
  stickyHeaders,
}: Readonly<TableProps<T>>) {
  const hasTotals = totals && totals.cells.size > 0
  const theadStyle = twMerge(
    'tw-z-50 tw-shadow tw-shadow-slate-200',
    stickyHeaders ? 'tw-sticky tw-top-0' : ''
  )
  return (
    <SimpleTable className={className}>
      <thead className={theadStyle}>
        <tr>
          {model.getLeafHeaders().map(h => (
            <HeaderWithBehaviour header={h} key={h.id} />
          ))}
        </tr>
        {hasTotals && (
          <tr>
            {model.getAllColumns().map(c => (
              <th
                key={c.id}
                className={twMerge(defaultHeaderClass, 'tw-bg-white')}
              >
                <div
                  className={twMerge(
                    'tw-flex tw-flex-row',
                    totals.cells.get(c.id)?.className ?? 'tw-justify-end'
                  )}
                >
                  <Tooltip content={totals.cells.get(c.id)?.tooltip}>
                    {totals.cells.get(c.id)?.value}
                  </Tooltip>
                </div>
              </th>
            ))}
          </tr>
        )}
      </thead>
      <SimpleTable.Body>
        {loading && (
          <tr>
            <td
              colSpan={model.getAllColumns().length}
              className="tw-text-xl tw-font-bold"
            >
              <div
                data-testid="tableLoadingContainer"
                className="tw-flex tw-min-h-[300px] tw-flex-row tw-items-center tw-justify-around"
              >
                {loadingMessage ?? 'Loading...'}
              </div>
            </td>
          </tr>
        )}
        {error && !loading && (
          <tr>
            <td colSpan={model.getAllColumns().length}>
              {errorMessage ?? (
                <div className="tw-flex tw-min-h-[300px] tw-flex-col tw-items-center tw-justify-center tw-gap-6">
                  <div>
                    <Icon icon={iconError} width={64} height={64} />
                  </div>
                  <div className="tw-text-xl">Oops, something went wrong.</div>
                  <div className="tw-text-sm">
                    Please refresh your browser to try again.
                  </div>
                </div>
              )}
            </td>
          </tr>
        )}
        {!error && !loading && !model.getRowModel().rows.length && (
          <tr>
            <td colSpan={model.getAllColumns().length}>
              {noDataMessage ?? (
                <div className="tw-flex tw-min-h-[300px] tw-flex-col tw-items-center tw-justify-center tw-gap-6">
                  <div>
                    <Icon icon={iconSearch} width={64} height={64} />
                  </div>
                  <div className="tw-text-xl">No results found</div>
                  <div className="tw-text-sm tw-text-gray-400">
                    No results match your filter criteria. Adjust your filters
                    and try again.
                  </div>
                </div>
              )}
            </td>
          </tr>
        )}
        {!error && !loading && (
          <>
            {model.getRowModel().rows.map((row, i) => {
              const isInteractiveRow = row.getCanExpand() || onRowClick
              return (
                <React.Fragment key={row.id}>
                  <SimpleTable.Row
                    className={twMerge(
                      row.getIsExpanded() ? 'tw-bg-gray-100' : undefined,
                      rowClassName !== undefined
                        ? rowClassName(row)
                        : undefined,
                      isInteractiveRow ? 'tw-cursor-pointer' : undefined
                    )}
                    isSelected={row.getIsSelected()}
                    isFirstRow={!totals && i === 0}
                    onClick={
                      isInteractiveRow
                        ? () => {
                            if (onRowClick) onRowClick(row)
                            row.getToggleExpandedHandler()()
                          }
                        : undefined
                    }
                  >
                    {row.getVisibleCells().map(cell => (
                      <TableCell cell={cell} key={cell.id} />
                    ))}
                  </SimpleTable.Row>
                  {row.getIsExpanded() && renderExpandedRow && (
                    <tr>
                      <td colSpan={row.getVisibleCells().length}>
                        {renderExpandedRow({ row })}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </SimpleTable.Body>
    </SimpleTable>
  )
}

function HeaderWithBehaviour<T extends Record<string, any>>({
  header,
}: {
  readonly header: Header<T, unknown>
}) {
  const rowCount = header.getContext().table.getPreFilteredRowModel()
    .rows.length
  const column = header.column
  const meta = column.columnDef.meta

  if (rowCount && meta?.type === 'select-checkbox')
    return <HeaderWithSelectToggle header={header} />

  if (rowCount && meta?.type === 'filter-faceted' && column.getCanFilter())
    return <HeaderWithFacetFilter header={header} />

  if (rowCount && meta?.type === 'filter-search' && column.getCanFilter())
    return <HeaderWithSearchFilter header={header} />

  if (rowCount && column.getCanSort())
    return <HeaderWithSortToggle header={header} />

  return (
    <th
      className={twMerge(
        defaultHeaderClass,
        TableStyles.align[meta?.align ?? 'left']
      )}
    >
      {flexRender(column.columnDef.header, header.getContext())}
    </th>
  )
}

type FilterPopoverModel = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  setTriggerRef: (node: HTMLElement | null) => void
  getTriggerProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
  setFloatingRef: (node: HTMLElement | null) => void
  getFloatingProps: (
    userProps?: React.HTMLProps<HTMLElement>
  ) => Record<string, unknown>
  floatingStyles: React.CSSProperties
}

function useFilterPopoverModel(): FilterPopoverModel {
  const [isOpen, setIsOpen] = useState(false)
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(10),
      autoPlacement({ allowedPlacements: ['top-start', 'bottom-start'] }),
    ],
    whileElementsMounted: autoUpdate,
  })
  const click = useClick(context, { toggle: true })
  const dismiss = useDismiss(context)
  const role = useRole(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ])

  return {
    setTriggerRef: refs.setReference,
    getTriggerProps: getReferenceProps,
    setFloatingRef: refs.setFloating,
    floatingStyles,
    getFloatingProps,
    isOpen,
    setIsOpen,
  }
}

function FilterPopover(props: {
  readonly model: FilterPopoverModel
  readonly children: React.JSX.Element
}) {
  if (props.model.isOpen)
    return (
      <FloatingPortal>
        <div
          className="tw-z-[1000]"
          ref={props.model.setFloatingRef}
          style={props.model.floatingStyles}
          {...props.model.getFloatingProps()}
        >
          {props.children}
        </div>
      </FloatingPortal>
    )
  return <></>
}

interface HeaderWithFilterProps<T extends Record<string, any>> {
  header: Header<T, unknown>
}

function HeaderWithFilterTemplate<T extends Record<string, any>>({
  header,
  currentFilterValue,
  icon,
  popoverModel,
  children,
}: {
  readonly header: Header<T, unknown>
  readonly currentFilterValue?: unknown
  readonly icon: IconifyIcon
  readonly popoverModel: FilterPopoverModel
  readonly children: React.JSX.Element
}) {
  const meta = header.column.columnDef.meta
  return (
    <th
      className={twMerge(
        defaultHeaderClass,
        TableStyles.align[meta?.align ?? 'left']
      )}
    >
      <div className="tw-relative tw-left-0 tw-top-0">
        <div
          className={twMerge(
            'tw-flex tw-cursor-pointer tw-select-none tw-flex-row tw-flex-nowrap tw-justify-start tw-gap-2 tw-border-0 tw-bg-transparent focus:tw-border-0',
            TableStyles.align[meta?.align ?? 'left']
          )}
          ref={popoverModel.setTriggerRef}
          {...popoverModel.getTriggerProps()}
        >
          <div>
            {flexRender(header.column.columnDef.header, header.getContext())}
          </div>
          <div className="tw-shrink-0">
            <Icon
              icon={icon}
              className={
                currentFilterValue ? 'tw-text-blue-600' : 'tw-text-gray-500'
              }
              width={16}
              height={16}
            />
          </div>
        </div>
      </div>
      <FilterPopover model={popoverModel}>{children}</FilterPopover>
    </th>
  )
}

function HeaderWithSearchFilter<T extends Record<string, any>>({
  header,
}: Readonly<HeaderWithFilterProps<T>>) {
  const popoverModel = useFilterPopoverModel()
  const currentFilterValue = header.column.getFilterValue() as
    | string
    | undefined

  return (
    <HeaderWithFilterTemplate
      header={header}
      currentFilterValue={currentFilterValue}
      icon={iconSearch}
      popoverModel={popoverModel}
    >
      <SearchFilter
        column={header.column}
        onClose={() => {
          popoverModel.setIsOpen(false)
        }}
      />
    </HeaderWithFilterTemplate>
  )
}

function HeaderWithFacetFilter<T extends Record<string, any>>({
  header,
}: Readonly<HeaderWithFilterProps<T>>) {
  const popoverModel = useFilterPopoverModel()
  const currentFilterValue = header.column.getFilterValue()

  return (
    <HeaderWithFilterTemplate
      header={header}
      currentFilterValue={currentFilterValue}
      icon={currentFilterValue ? iconFilterFilled : iconFilterOutline}
      popoverModel={popoverModel}
    >
      <HeaderFacetFilter
        column={header.column}
        onClose={() => {
          popoverModel.setIsOpen(false)
        }}
      />
    </HeaderWithFilterTemplate>
  )
}

function HeaderWithSortToggle<T extends Record<string, any>>({
  header,
}: {
  readonly header: Header<T, unknown>
}) {
  const meta = header.column.columnDef.meta
  return (
    <th
      className={twMerge(
        defaultHeaderClass,
        TableStyles.align[meta?.align ?? 'left']
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div
        className={twMerge(
          'tw-flex tw-cursor-pointer tw-flex-row tw-flex-nowrap tw-justify-start tw-gap-2',
          TableStyles.align[meta?.align ?? 'left']
        )}
        onClick={header.column.getToggleSortingHandler()}
      >
        <div>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        <div className="tw-shrink-0">
          <SortIcon column={header.column} />
        </div>
      </div>
    </th>
  )
}

function HeaderWithSelectToggle<T extends Record<string, any>>({
  header,
}: {
  readonly header: Header<T, unknown>
}) {
  const table = header.getContext().table

  function onClick() {
    if (table.getIsAllRowsSelected()) table.toggleAllRowsSelected()
    else table.toggleAllRowsSelected(!table.getIsSomeRowsSelected())
  }

  return (
    <th
      className={`${defaultHeaderClass} tw-w-0 tw-text-center`}
      onClick={onClick}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  )
}

function TableCell<T extends Record<string, any>>({
  cell,
}: {
  readonly cell: Cell<T, unknown>
}) {
  const meta = cell.column.columnDef.meta
  const onClick =
    meta?.type === 'select-checkbox'
      ? cell.row.getToggleSelectedHandler()
      : undefined

  return (
    <SimpleTable.Cell
      noWrap={meta?.noWrap}
      align={meta?.align ?? 'left'}
      onClick={onClick}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </SimpleTable.Cell>
  )
}

function SortIcon<T extends Record<string, any>>({
  column,
}: {
  readonly column: Column<T>
}) {
  const iconColor = column.getIsSorted()
    ? 'tw-text-blue-600'
    : 'tw-text-gray-500'

  if (column.getCanSort()) {
    if (column.getIsSorted() === false)
      return (
        <Icon
          className={iconColor}
          icon={iconSortNone}
          width={16}
          height={16}
        />
      )
    if (column.getIsSorted() === 'asc')
      return (
        <Icon className={iconColor} icon={iconSortAsc} width={16} height={16} />
      )
    if (column.getIsSorted() === 'desc')
      return (
        <Icon
          className={iconColor}
          icon={iconSortDesc}
          width={16}
          height={16}
        />
      )
  }
  return <></>
}
