import { createContext, useContext } from 'react'

export type AssetDetailContextValue = {
  assetId: number
}
export const AssetDetailContext = createContext<AssetDetailContextValue | null>(
  null
)

export function useAssetDetailContext(): AssetDetailContextValue {
  const context = useContext(AssetDetailContext)
  if (!context) {
    throw new Error('No AssetDetailContext available')
  }
  return context
}
