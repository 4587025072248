import React from 'react'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import SunIcon from '@/assets/icons/SunIcon.svg?react'

interface SolarSpinnerProps {
  className?: string
  width?: number
  height?: number
}

export function SolarSpinner({
  className,
  width = 32,
  height = 32,
}: Readonly<SolarSpinnerProps>): React.JSX.Element {
  return (
    <SunIcon
      data-testid="solarSpinner"
      className={twMerge(className, 'tw-animate-spin')}
      width={width}
      height={height}
    />
  )
}
