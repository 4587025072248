import { Maybe } from '@/helpers/TypeHelpers.ts'

export type SnapshotValueDisplayType = 'default' | 'primary' | 'danger'

type Props = {
  value: Maybe<string | number>
  displayType?: SnapshotValueDisplayType
}

export default function SnapshotValue({
  value,
  displayType = 'default',
}: Readonly<Props>) {
  const valueString = value?.toString() ?? ''
  const getClassNames = () => {
    switch (displayType) {
      case 'danger':
        return 'tw-font-semibold tw-text-red-700'
      case 'primary':
        return 'tw-text-2xl tw-font-bold tw-text-gray-900'
      default:
        return ''
    }
  }

  return (
    <div data-testid="snapshot-value" className={getClassNames()}>
      {valueString}
    </div>
  )
}
