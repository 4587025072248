import {
  CommercialServiceActivity,
  ResidentialServiceActivity,
} from '@/generated'
import { Row } from '@tanstack/react-table'

export function pendingClientActionSortFn(
  rowA: Row<CommercialServiceActivity> | Row<ResidentialServiceActivity>,
  rowB: Row<CommercialServiceActivity> | Row<ResidentialServiceActivity>
): 0 | 1 | -1 {
  const { pendingClientAction: pendingActionA } = rowA.original
  const { pendingClientAction: pendingActionB } = rowB.original
  if (pendingActionA === pendingActionB) {
    return 0
  }
  return pendingActionA ? 1 : -1
}
