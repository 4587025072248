import { Moment } from 'moment/moment'
import React, { useMemo, useEffect } from 'react'
import { YearMonthFormat, DateFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import {
  ISO_DATE_FORMAT,
  parseMonthlyBucket,
  toExcelCsvDateFormat,
} from '@/helpers/MomentHelpers.ts'
import { CellContext, ColumnDef } from '@tanstack/react-table'
import numericColumn, {
  Formats,
} from '@/components/core/table/columns/numericColumn.ts'
import useTable from '@/components/core/table/useTable.tsx'
import Table from '@/components/core/table/Table.tsx'
import { TotalDef, useTotals } from '@/components/core/table/useTotals.tsx'
import { Aggregations } from '@/components/core/table/aggregation.ts'
import { ActualVsExpectedCell } from '@/components/table/cells/ActualVsExpectedCell.tsx'
import { MonthlyPerformanceGraph } from '@/components/graphs/MonthlyPerformanceGraph.tsx'
import {
  useAssetMetadataQuery,
  useAssetPerformanceMonthlyQuery,
} from '@/api/AssetQueries.ts'
import { MonthlyPerformanceRow } from '@/generated'
import { AssetPerformanceCsvData } from '@/pages/assetdetail/performance/AssetPerformanceTab.tsx'
import { CsvColumnOption } from '../export/ExportCsvButton'
import { getAssetFilename } from './helpers'

interface AssetMonthlyPerformanceProps {
  assetId: number
  selectedMinDate: Moment
  selectedMaxDate: Moment
  setCsvData: React.Dispatch<React.SetStateAction<AssetPerformanceCsvData>>
}

const monthlyPerformanceColumns: CsvColumnOption<MonthlyPerformanceRow>[] = [
  { key: 'startDate', header: 'Date' },
  { key: 'actualEnergy', header: 'actualEnergy' },
  { key: 'expectedEnergy', header: 'Expected Energy kWh' },
  { key: 'actualVsPredictedEnergy', header: 'Actual vs Predicted (%)' },
  { key: 'actualVsExpectedEnergy', header: 'Actual vs Predicted (%)' },
  { key: 'solarResourceRatio', header: 'Solar Resource Ratio (%)' },
]

export default function AssetMonthlyPerformance({
  assetId,
  selectedMinDate,
  selectedMaxDate,
  setCsvData,
}: Readonly<AssetMonthlyPerformanceProps>): React.JSX.Element {
  const performanceQuery = useAssetPerformanceMonthlyQuery(
    assetId,
    selectedMinDate.format(ISO_DATE_FORMAT),
    selectedMaxDate.format(ISO_DATE_FORMAT)
  )

  const tableColumns = useMemo<ColumnDef<MonthlyPerformanceRow>[]>(
    () => [
      {
        accessorKey: 'startDate',
        header: 'Date',
        cell: MonthlyBucketCell,
      },
      numericColumn({
        key: 'actualEnergy',
        header: 'Actual Energy (kWh)',
        format: 'integer',
      }),
      numericColumn({
        key: 'expectedEnergy',
        header: 'Expected Energy (kWh)',
        format: 'integer',
      }),
      numericColumn({
        key: 'actualVsPredictedEnergy',
        header: 'Actual vs Predicted (%)',
        format: 'percent',
      }),
      numericColumn({
        key: 'actualVsExpectedEnergy',
        header: 'Actual vs Expected (%)',
        format: 'percent',
        cell: ActualVsExpectedCell,
      }),
      numericColumn({
        key: 'solarResourceRatio',
        header: 'Solar Resource Ratio (%)',
        format: 'percent',
      }),
    ],
    []
  )

  const tableRows = useMemo(
    () => performanceQuery.data?.data ?? [],
    [performanceQuery.data]
  )
  const table = useTable(tableRows, tableColumns, {
    initialSort: [{ id: 'startDate', desc: true }],
  })
  const totalDefs = useMemo<TotalDef<MonthlyPerformanceRow>[]>(
    () => [
      {
        key: 'startDate',
        format: () => 'TOTAL',
        className: 'tw-justify-start',
      },
      {
        key: 'actualEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'predictedEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'expectedEnergy',
        agg: Aggregations.sum,
        format: Formats.integer,
      },
      {
        key: 'actualVsPredictedEnergy',
        agg: Aggregations.average,
        format: Formats.percent,
      },
      {
        key: 'actualVsExpectedEnergy',
        agg: Aggregations.average,
        format: Formats.percent,
      },
      {
        key: 'solarResourceRatio',
        agg: Aggregations.average,
        format: Formats.percent,
      },
    ],
    []
  )
  const allRows = table.getSortedRowModel().rows
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  useEffect(() => {
    setCsvData({
      columns: monthlyPerformanceColumns,
      fileName: getAssetFilename(
        assetMetadataQuery.data?.assetName,
        'monthly_asset_performance'
      ),
      rows: () =>
        allRows.map(r => ({
          ...r.original,
          startDate: `${toExcelCsvDateFormat(r.original.startDate)} - ${toExcelCsvDateFormat(r.original.endDate)}`,
        })),
    })
  }, [allRows, assetMetadataQuery.data?.assetName, setCsvData])

  const totals = useTotals<MonthlyPerformanceRow>(tableRows, totalDefs)

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-6">
      <MonthlyPerformanceGraph performanceRows={tableRows} />
      <div className="tw-overflow-auto">
        <Table model={table} totals={totals} />
      </div>
    </div>
  )
}

function MonthlyBucketCell(
  ctx: Readonly<CellContext<MonthlyPerformanceRow, unknown>>
): React.JSX.Element {
  const { startDate, endDate } = ctx.row.original
  const monthlyBucket = parseMonthlyBucket(startDate, endDate)
  if (!monthlyBucket) {
    return <>{`${startDate} - ${endDate}`}</>
  }
  const formattedDate = monthlyBucket.isFullMonth
    ? YearMonthFormat.format(monthlyBucket.startDate.toDate())
    : `${DateFormat.format(monthlyBucket.startDate.toDate())} - ${DateFormat.format(monthlyBucket.endDate.toDate())}`
  return <>{formattedDate}</>
}
