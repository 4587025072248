import { CellContext } from '@tanstack/react-table'
import { CommercialServiceActivity } from '@/generated'
import { Maybe } from '@/helpers/TypeHelpers.ts'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'

export default function AssetNameCell(
  ctx: Readonly<CellContext<CommercialServiceActivity, unknown>>
): React.JSX.Element {
  const assetId = ctx.row.original.assetId
  const assetName = ctx.getValue<Maybe<string>>()
  return <AssetDetailLink assetId={assetId}>{assetName}</AssetDetailLink>
}
