import { BaseServiceActivity } from '@/generated'
import { twMerge } from '@/helpers/CustomTwMerge.ts'
import { isServiceActivityClosed } from '@/helpers/ServiceActivityHelper.ts'
import { Row } from '@tanstack/react-table'

export default function serviceActivityTableRowStyle<
  T extends BaseServiceActivity,
>(row: Row<T>) {
  return twMerge(
    isServiceActivityClosed(row.original) && 'tw-bg-gray-50',
    'hover:tw-bg-gray-50'
  )
}
