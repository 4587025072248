import { Card } from '@/components/core/cards/Card.tsx'
import { ServiceActivitySnapshot } from '@/generated'
import { ReactElement } from 'react'
import SnapshotSections from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSections.tsx'
import SnapshotSection from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSection.tsx'
import SnapshotLine from '@/pages/serviceActivity/residential/snapShots/components/SnapshotLine.tsx'
import SnapshotValue, {
  SnapshotValueDisplayType,
} from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import SnapshotSummary from '@/pages/serviceActivity/residential/snapShots/components/SnapshotSummary.tsx'
import { formatNumber, PercentNumberFormat } from '@/helpers/NumberFormats.ts'

type Props = {
  snapshot?: ServiceActivitySnapshot
  icon: React.JSX.Element
  description: string
  ticketLabel: string
  ticketTooltip: ReactElement
  percentLabel: string
  showAdditionalFields?: boolean
}

export default function SnapshotCard({
  snapshot,
  icon,
  description,
  ticketLabel,
  ticketTooltip,
  percentLabel,
  showAdditionalFields = false,
}: Readonly<Props>) {
  // Early return if no snapshot is provided
  if (!snapshot) return null

  const {
    countOfServiceActivitiesOpenedForCategory = 0,
    percentageOfAssetsWithServiceActivityOpenedForCategory = 0,
    countOfPendingClientActions,
    countOfAssets,
    countOfNonCoveredServiceRequests,
    countOfRemoteTroubleShootings,
    countOfFieldServices,
  } = snapshot

  // Helper function to create SnapshotLine blocks
  const renderSnapshotLine = (
    label: string,
    value: number,
    displayType: SnapshotValueDisplayType = 'default',
    tooltip?: ReactElement
  ) => (
    <SnapshotLine label={label} description={tooltip}>
      <SnapshotValue value={formatNumber(value)} displayType={displayType} />
    </SnapshotLine>
  )

  return (
    <Card className="tw-h-full">
      <SnapshotSummary
        icon={icon}
        count={countOfAssets}
        description={description}
      />

      <SnapshotSections>
        <SnapshotSection>
          {renderSnapshotLine(
            ticketLabel,
            countOfServiceActivitiesOpenedForCategory,
            'primary',
            ticketTooltip
          )}
          <SnapshotLine
            label={percentLabel}
            description={
              <>
                This percentage reflects the number of{' '}
                <span className="tw-font-bold">assets</span> with at least one
                open ticket, not the total number of tickets. Multiple tickets
                per asset aren&apos;t counted to avoid skewing the ratio.
              </>
            }
          >
            <SnapshotValue
              value={PercentNumberFormat().positive.format(
                percentageOfAssetsWithServiceActivityOpenedForCategory
              )}
            />
          </SnapshotLine>
        </SnapshotSection>

        <SnapshotSection>
          {renderSnapshotLine(
            'Pending client action',
            countOfPendingClientActions,
            'danger'
          )}
        </SnapshotSection>

        <SnapshotSection show={showAdditionalFields}>
          {renderSnapshotLine(
            'Non-Covered Service Requests',
            countOfNonCoveredServiceRequests
          )}
        </SnapshotSection>
        <SnapshotSection show={showAdditionalFields}>
          {renderSnapshotLine(
            'Remote Troubleshooting',
            countOfRemoteTroubleShootings
          )}

          {renderSnapshotLine('Field Service', countOfFieldServices)}
        </SnapshotSection>
      </SnapshotSections>
    </Card>
  )
}
