import { ComponentProps, PropsWithChildren } from 'react'
import { twJoin } from 'tailwind-merge'
import { IconifyIcon } from '@iconify/react'
import { Icon } from '@iconify/react/offline'
import {
  ButtonAlign,
  ButtonBaseStyles,
  ButtonColor,
  ButtonSize,
  ButtonIconSize,
} from '@/components/core/buttons/ButtonStyles.ts'

export interface ButtonProps
  extends PropsWithChildren<Omit<ComponentProps<'button'>, 'className'>> {
  readonly size?: keyof typeof ButtonSize
  readonly color?: keyof typeof ButtonColor
  readonly align?: keyof typeof ButtonAlign
  readonly icon?: IconifyIcon
  readonly block?: boolean
}

export default function Button({
  size,
  color,
  align,
  block,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      className={twJoin(
        ButtonBaseStyles,
        size ? ButtonSize[size] : ButtonSize.default,
        color ? ButtonColor[color] : ButtonColor.default,
        align ? ButtonAlign[align] : ButtonAlign.default,
        block ? 'tw-w-full' : ''
      )}
    >
      {props.icon ? (
        <div className="tw-space-x-1">
          <Icon
            icon={props.icon}
            className="tw-inline"
            width={size ? ButtonIconSize[size] : ButtonIconSize.default}
            height={size ? ButtonIconSize[size] : ButtonIconSize.default}
          />
          <span>{props.children}</span>
        </div>
      ) : (
        props.children
      )}
    </button>
  )
}
