import { Portal } from '@headlessui/react'
import CaseViewModalContent from './CaseViewModalContent.tsx'
import { useAssetCaseQuery } from '@/api/AssetQueries.ts'
import { Modal } from '@/components/core/modals/Modal.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'
import { SolarSpinner } from '@/components/core/spinner/SolarSpinner.tsx'

interface CaseViewModalProps {
  show: boolean
  assetId: number
  caseId: string
  onClose: () => void
}

export default function CaseViewModal({
  show,
  assetId,
  caseId,
  onClose,
}: Readonly<CaseViewModalProps>) {
  const assetCaseQuery = useAssetCaseQuery(assetId, caseId)

  return (
    <Portal>
      <Modal className="tw-w-full tw-max-w-6xl" show={show} onClose={onClose}>
        <Modal.Header onClose={onClose}></Modal.Header>
        <Modal.Body>
          {assetCaseQuery.isPending && (
            <div className="tw-flex tw-justify-center">
              <SolarSpinner height={72} width={72} />
            </div>
          )}
          {assetCaseQuery.isError && (
            <Alert color="red">
              Something went wrong fetching the information for this case.
            </Alert>
          )}
          {assetCaseQuery.isSuccess && (
            <CaseViewModalContent caseData={assetCaseQuery.data} />
          )}
        </Modal.Body>
      </Modal>
    </Portal>
  )
}
