import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { useResidentialAssetServiceActivityQuery } from '@/api/AssetQueries.ts'
import { useMemo, useState } from 'react'
import useResidentialServiceActivityTableColumns, {
  ResidentialServiceActivityTableColumnVisibility,
} from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableColumns.tsx'
import useResidentialServiceActivityTableFilters from '@/pages/serviceActivity/residential/useResidentialServiceActivityTableFilters.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'
import assetServiceActivitySort from '@/pages/assetdetail/serviceActivity/assetServiceActivitySort.ts'
import useTable from '@/components/core/table/useTable.tsx'
import { Row } from '@tanstack/react-table'
import { ResidentialServiceActivity } from '@/generated'
import ServiceActivityDrawer from '@/features/serviceactivity/detailsDrawer/ServiceActivityDrawer.tsx'
import { ServiceActivityPageSize } from '@/features/serviceactivity/ServiceActivityPageSize.ts'
import ServiceActivityTable from '@/pages/serviceActivity/shared/ServiceActivityTable.tsx'
import useResidentialServiceActivityCsvColumns from '@/pages/serviceActivity/residential/useResidentialServiceActivityCsvColumns.ts'

export default function ResidentialAssetServiceActivityTab() {
  const { assetId } = useAssetDetailContext()
  const { data, isLoading, isError } = useResidentialAssetServiceActivityQuery(
    assetId,
    true
  )
  const sortedRows = useMemo(
    () => (data?.data ?? []).sort(assetServiceActivitySort),
    [data?.data]
  )

  const [selectedServiceActivityId, setSelectedServiceActivityId] = useState<
    string | undefined
  >()
  const columns = useResidentialServiceActivityTableColumns(
    ServiceActivityPageName.assetServiceActivity
  )
  const filterDefs = useResidentialServiceActivityTableFilters(
    ServiceActivityPageName.assetServiceActivity
  )
  const tableModel = useTable(sortedRows, columns, {
    columnVisibility: ResidentialServiceActivityTableColumnVisibility,
    pageSize: ServiceActivityPageSize,
  })
  const exportCsvColumns = useResidentialServiceActivityCsvColumns()

  const handleClickRow = (row: Row<ResidentialServiceActivity>) => {
    setSelectedServiceActivityId(row.original.id)
  }

  const handleCloseDrawer = () => {
    setSelectedServiceActivityId(undefined)
  }

  return (
    <div>
      <ServiceActivityTable
        tableModel={tableModel}
        isLoading={isLoading}
        isError={isError}
        filterDefs={filterDefs}
        handleClickRow={handleClickRow}
        csvOptions={{ exportCsvColumns }}
      />

      {selectedServiceActivityId && (
        <ServiceActivityDrawer
          serviceActivityId={selectedServiceActivityId}
          onClose={handleCloseDrawer}
          onChangeServiceActivityId={serviceActivityId => {
            setSelectedServiceActivityId(serviceActivityId)
          }}
        />
      )}
    </div>
  )
}
