import React, { useContext, useEffect } from 'react'
import { LoadingPage } from '@/pages/LoadingPage.tsx'
import { ErrorModal } from '@/components/error/ErrorModal.tsx'
import { useNavigate } from 'react-router-dom'
import { flushLogEntries, useLogger } from '@/helpers/Logging.ts'
import { AuthContext } from '@/contexts/auth/AuthProvider.tsx'

/**
 * Ensures that the handleLoginRedirect() call only occurs once, even if this component re-renders (e.g. due to a
 * change in authContext). The redirect back from the Okta domain to complete login will always trigger a fresh
 * page/app load, so we should never need to execute it a second time.
 *
 * This follows the general guidelines for initialization code described in the React
 * docs: https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application
 */
let handledRedirect = false

export function LoginCallback(): React.JSX.Element {
  const logger = useLogger()
  const authContext = useContext(AuthContext)

  const [callbackError, setCallbackError] = React.useState<Error | null>(null)

  useEffect(() => {
    if (!handledRedirect) {
      logger.info({ message: 'LoginCallback: Executing handleLoginRedirect' })
      authContext.handleLoginRedirect().catch((e: unknown) => {
        setCallbackError(e as Error)
      })
      handledRedirect = true
    }
  }, [authContext, logger])

  /**
   * Re-rendering OktaLoginCallback component in the middle of finishing the auth process may lead to
   * errors, adding a log message to try to capture this occurrence.
   */
  logger.info({ message: 'LoginCallback: Rendering' })

  const error = callbackError ?? authContext.authError
  if (error) {
    return <AuthErrorModal error={error} />
  }

  return <LoadingPage />
}

interface AuthErrorModalProps {
  error: Error
}

function AuthErrorModal({
  error,
}: Readonly<AuthErrorModalProps>): React.JSX.Element {
  const navigate = useNavigate()

  // Clunky attempt to flush the log entries before the user clicks away and gets redirected to login again.
  // TODO: https://omnidian.atlassian.net/browse/HYP-2807 - If the endpoint was a POST, we could add an
  // onunload handler to the logger, to flush if the user leaves the page.
  useEffect(() => {
    flushLogEntries()
  }, [])

  return (
    <ErrorModal
      error={error}
      onRetry={() => {
        navigate('/')
      }}
    />
  )
}
