import React, { PropsWithChildren } from 'react'
import { IconifyIcon } from '@iconify/react'
import { twJoin } from 'tailwind-merge'
import { Icon } from '@iconify/react/offline'
import iconClose from '@iconify/icons-heroicons/x-mark-20-solid'

const AlertColor = {
  blue: `tw-text-blue-800 tw-bg-blue-50`,
  red: `tw-text-red-800 tw-bg-red-50`,
  green: `tw-text-green-800 tw-bg-green-50`,
  yellow: `tw-text-yellow-800 tw-bg-yellow-50`,
  gray: `tw-text-gray-800 tw-bg-gray-50`,
}

const BorderColor = {
  blue: `tw-border-blue-300`,
  red: `tw-border-red-300`,
  green: `tw-border-green-300`,
  yellow: `tw-border-yellow-300`,
  gray: `tw-border-gray-300`,
}

const CloseButtonColor = {
  blue: `tw-text-blue-500 tw-bg-blue-50 hover:tw-bg-blue-200 focus:tw-ring-blue-400`,
  red: `tw-text-red-500 tw-bg-red-50 hover:tw-bg-red-200 focus:tw-ring-red-400`,
  green: `tw-text-green-500 tw-bg-green-50 hover:tw-bg-green-200 focus:tw-ring-green-400`,
  yellow: `tw-text-yellow-500 tw-bg-yellow-50 hover:tw-bg-yellow-200 focus:tw-ring-yellow-400`,
  gray: `tw-text-gray-500 tw-bg-gray-50 hover:tw-bg-gray-200 focus:tw-ring-gray-400`,
}

const BorderKind = {
  none: `tw-rounded-lg`,
  full: `tw-border tw-border-solid tw-rounded-lg`,
  accent: `tw-border-0 tw-border-t-4 tw-border-solid`,
}

export interface AlertProps extends PropsWithChildren {
  readonly title?: string
  readonly messages?: string[]
  readonly className?: string
  readonly color?: keyof typeof AlertColor
  readonly icon?: IconifyIcon
  readonly border?: keyof typeof BorderKind
  readonly onClose?: () => void
}

export default function Alert({
  color = 'gray',
  border = 'none',
  title,
  messages,
  className,
  icon,
  onClose,
  children,
}: AlertProps): React.JSX.Element {
  const messageList =
    messages && messages.length > 0 ? (
      <ul>
        {messages.map(message => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    ) : undefined

  return (
    <div
      role="alert"
      className={twJoin(
        `tw-p-4`,
        AlertColor[color],
        BorderKind[border],
        border !== 'none' ? BorderColor[color] : null,
        className
      )}
    >
      <div className="tw-flex tw-flex-col tw-gap-2">
        <div className="tw-flex tw-flex-row tw-justify-between tw-gap-2">
          <div className="tw-flex tw-flex-row tw-items-center tw-gap-3">
            {icon && (
              <Icon icon={icon} className="tw-size-5 tw-shrink-0 tw-grow-0" />
            )}
            <div className="tw-text-sm">{title}</div>
          </div>
          {onClose && (
            <button
              className={twJoin(
                `tw--mx-1.5 tw--my-1.5 tw-ml-auto tw-inline-flex tw-h-8 tw-w-8 tw-rounded-lg tw-border-0 tw-bg-transparent tw-p-1.5 focus:tw-ring-2`,
                CloseButtonColor[color]
              )}
              onClick={onClose}
              aria-label="Close"
            >
              <span className="tw-sr-only">Close</span>
              <Icon icon={iconClose} className="tw-size-5" />
            </button>
          )}
        </div>
        {messageList && <div className="tw-text-sm">{messageList}</div>}
      </div>
      {children && <div className="tw-text-sm">{children}</div>}
    </div>
  )
}
