import PagerStatus from '@/components/core/pager/PagerStatus.tsx'
import { TableModel } from '@/components/core/table/useTable.tsx'
import Pager from '@/components/core/pager/Pager.tsx'

interface ServiceActivityTableFooterProps<T> {
  tableModel: TableModel<T>
  scrollTarget?: HTMLElement | null
}

export default function ServiceActivityTableFooter<T>({
  tableModel,
  scrollTarget,
}: Readonly<ServiceActivityTableFooterProps<T>>) {
  const itemCount = tableModel.getFilteredRowModel().rows.length
  const pageSize = tableModel.getState().pagination.pageSize
  const currentPageIndex = tableModel.getState().pagination.pageIndex

  if (itemCount <= pageSize) return <></>

  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-6">
      <PagerStatus
        pageSize={pageSize}
        itemCount={itemCount}
        currentPageIndex={currentPageIndex}
      />
      <Pager
        pageSize={pageSize}
        itemCount={itemCount}
        currentPageIndex={currentPageIndex}
        onSelectedPageIndexChanged={pageNumber => {
          tableModel.setPageIndex(pageNumber)
          scrollTarget?.scrollIntoView({
            behavior: 'instant',
            block: 'start',
          })
        }}
        maxPageIndexButtons={0}
        showLimitPageButtons={false}
      />
    </div>
  )
}
