import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useAuth } from '@/components/auth/AuthHooks'
import {
  ApiControllerApi,
  AssetManager,
  AssetPerformanceRow,
  Branding,
  Configuration,
} from '@/generated'
import { useMemo } from 'react'
import { useAxiosClientConfig } from '@/helpers/AxiosHooks.ts'

import { useClientProfileId } from '@/contexts/ClientProfileHooks.ts'

const queryKeys = {
  branding: 'branding',
  assetsPerformance: 'assetsPerformance',
  assetManagers: 'assetManagers',
}

function useApiClient() {
  const config = useAxiosClientConfig()
  return useMemo(() => {
    return new ApiControllerApi(new Configuration(config))
  }, [config])
}

export function useBrandingQuery(): UseQueryResult<Branding> {
  const auth = useAuth()
  const apiClient = useApiClient()
  const clientProfileId = useClientProfileId()

  return useQuery({
    queryKey: [queryKeys.branding, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await apiClient.getBranding(clientProfileId, {
        signal,
      })
      return response.data.branding
    },
    gcTime: Infinity,
    staleTime: Infinity,
    enabled: auth.isAuthenticated,
  })
}

export function useAssetsPerformanceQuery(
  isoStartDate: string,
  isoEndDate: string
): UseQueryResult<AssetPerformanceRow[]> {
  const auth = useAuth()
  const apiClient = useApiClient()
  const clientProfileId = useClientProfileId()

  return useQuery({
    queryKey: [
      queryKeys.assetsPerformance,
      isoStartDate,
      isoEndDate,
      clientProfileId,
    ],
    queryFn: async ({ signal }) => {
      const response = await apiClient.getAssetsPerformance(
        isoStartDate,
        isoEndDate,
        clientProfileId,
        { signal }
      )
      return response.data.data
    },
    gcTime: 0,
    staleTime: Infinity,
    enabled: auth.isAuthenticated,
  })
}

export function useAssetManagersQuery(): UseQueryResult<AssetManager[]> {
  const auth = useAuth()
  const apiClient = useApiClient()
  const clientProfileId = useClientProfileId()
  return useQuery({
    queryKey: [queryKeys.assetManagers, clientProfileId],
    queryFn: async ({ signal }) => {
      const response = await apiClient.getAssetManagers(clientProfileId, {
        signal,
      })
      return response.data.managers
    },
    enabled: auth.isAuthenticated,
  })
}
