import { useRef } from 'react'
import ServiceActivityTableControls from '@/pages/serviceActivity/shared/ServiceActivityTableControls.tsx'
import { TableModel } from '@/components/core/table/useTable.tsx'
import { FilterDrawerFilterDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { CsvColumnOption } from '@/components/export/ExportCsvButton.tsx'
import { BaseServiceActivity } from '@/generated'
import Table from '@/components/core/table/Table.tsx'
import serviceActivityTableRowStyle from '@/features/serviceactivity/serviceActivityTableRowStyle.ts'
import { ServiceActivityTableNoDataMessage } from '@/features/serviceactivity/ServiceActivityTableNoDataMessage.tsx'
import { Row } from '@tanstack/react-table'
import ServiceActivityTableFooter from '@/pages/serviceActivity/shared/ServiceActivityTableFooter.tsx'

interface ServiceActivityTableProps<T> {
  tableModel: TableModel<T>
  isLoading: boolean
  isError: boolean
  filterDefs?: Array<FilterDrawerFilterDef<T>>
  csvOptions?: {
    exportCsvColumns: Array<CsvColumnOption<T>>
    exportFileName?: string
  }
  handleClickRow?: (row: Row<T>) => void
}

export default function ServiceActivityTable<T extends BaseServiceActivity>({
  tableModel,
  isLoading,
  isError,
  filterDefs,
  csvOptions,
  handleClickRow,
}: Readonly<ServiceActivityTableProps<T>>) {
  const scrollTargetRef = useRef(null)

  return (
    <div ref={scrollTargetRef} className="tw-scroll-mt-4">
      <div className="tw-pb-6">
        <ServiceActivityTableControls
          csvOptions={csvOptions}
          filterDefs={filterDefs}
          tableModel={tableModel}
        />
      </div>
      <Table
        className="tw-shrink tw-pb-4"
        model={tableModel}
        loading={isLoading}
        error={isError}
        rowClassName={serviceActivityTableRowStyle}
        onRowClick={handleClickRow}
        noDataMessage={
          <ServiceActivityTableNoDataMessage tableModel={tableModel} />
        }
      />
      <ServiceActivityTableFooter
        tableModel={tableModel}
        scrollTarget={scrollTargetRef.current}
      />
    </div>
  )
}
