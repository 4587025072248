import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import ExternalLink from '@/components/link/ExternalLink.tsx'
import zendeskUrl from '@/helpers/zendeskUrl.ts'

interface ZendeskLinkProps {
  ticketId: string
}

export default function ZendeskTicketLink({
  ticketId,
}: Readonly<ZendeskLinkProps>) {
  return (
    <Tooltip content="Open in Zendesk">
      <ExternalLink to={zendeskUrl(ticketId)}>{ticketId}</ExternalLink>
    </Tooltip>
  )
}
