import { useMemo } from 'react'
import { FilterDrawerFilterDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { ResidentialServiceActivity } from '@/generated'
import {
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'
import { getAssetStageLabel } from '@/helpers/asset/AssetStageHelpers.ts'

export default function useResidentialServiceActivityTableFilters(
  pageName: ServiceActivityPageName
) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  return useMemo<
    Array<FilterDrawerFilterDef<ResidentialServiceActivity>>
  >(() => {
    const filters: FilterDrawerFilterDef<ResidentialServiceActivity>[] = []

    filters.push(
      priorityFilter('priority', rowData => rowData.priority),
      pendingClientActionFilter(
        'pendingClientAction',
        serviceActivityPendingClientActionFilterDataAccessor
      ),
      {
        type: 'faceted',
        key: 'ticketType',
        header: 'Ticket Type',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          rowData.ticketType,
      },
      creationReasonFilter(
        'creationReason',
        (rowData: ResidentialServiceActivity) => rowData.creationReason
      ),
      creationDateFilter('createdAt'),
      updatedDateFilter('updatedAt'),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      )
    )
    if (pageName == ServiceActivityPageName.serviceActivity) {
      filters.push({
        type: 'faceted',
        key: 'assetStage',
        header: 'Lifecycle Stage',
        rowDataAccessor: (rowData: ResidentialServiceActivity) =>
          getAssetStageLabel(rowData.assetStage),
      })
    }

    return filters
  }, [serviceCloudIntegrationEnabled, pageName])
}
