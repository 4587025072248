import { SiteHeader } from './SiteHeader.tsx'
import { Outlet } from 'react-router-dom'

export function MainLayout() {
  return (
    <div className="tw-flex tw-min-w-page tw-flex-col tw-items-center tw-overflow-x-auto">
      <SiteHeader />
      <div className="tw-inline-flex tw-max-h-page tw-flex-col tw-gap-8 tw-self-stretch tw-overflow-auto">
        <Outlet />
      </div>
    </div>
  )
}
