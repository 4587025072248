import React from 'react'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import { useAssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { Badge } from '@/components/core/badges/Badge.tsx'
import iconLocation from '@iconify/icons-material-symbols/location-on'
import { Icon } from '@iconify/react/offline'
import { NavTab, NavTabs } from '@/components/core/nav/NavTabs.tsx'
import {
  AssetDetailsTabRoute,
  AssetServiceActivityTabRoute,
} from '@/pages/assetdetail/AssetDetailRoutes.tsx'
import { Outlet } from 'react-router-dom'
import { AssetStage } from '@/generated'

export default function AssetDetailPageV2(): React.JSX.Element {
  return (
    <div className="tw-flex tw-flex-col tw-gap-8 tw-bg-white tw-px-20 tw-py-8">
      <AssetDetailHeader />
      <AssetDetailNavTabs />
      <Outlet />
    </div>
  )
}

const stageToLabelMap = new Map([
  [AssetStage.MetadataVerification, 'Metadata Verification'],
  [AssetStage.Pvt, 'Performance Verification'],
  [AssetStage.Active, 'Active'],
])

function AssetDetailHeader() {
  const { assetId } = useAssetDetailContext()
  const { data } = useAssetMetadataQuery(assetId)
  if (!data) return <></>

  return (
    <div>
      <div className="tw-mb-4 tw-inline-flex">
        {data.assetStage && (
          <Badge color="blue">{stageToLabelMap.get(data.assetStage)}</Badge>
        )}
      </div>
      <div className="tw-mb-2 tw-text-2xl tw-font-bold">{data.assetName}</div>
      <div className="tw-inline-flex tw-gap-2">
        <Icon icon={iconLocation} className="tw-size-6" />
        <span className="tw-text-gray-700">
          {data.address.street}, {data.address.city}, {data.address.state},{' '}
          {data.address.postalCode}
        </span>
      </div>
    </div>
  )
}

function AssetDetailNavTabs() {
  return (
    <NavTabs>
      <NavTab title="Details" path={AssetDetailsTabRoute} />
      <NavTab title="Service Activity" path={AssetServiceActivityTabRoute} />
    </NavTabs>
  )
}
