import { CellContext, ColumnDef, FilterFnOption } from '@tanstack/react-table'
import { ReactNode } from 'react'
import { NestedKeyOf } from '@/helpers/TypeHelpers.ts'
import TwoValueTableElement from '@/pages/serviceActivity/residential/tableComponents/TwoValueTableElement.tsx'

type TwoValuesColumnProps<T> = {
  accessorKey: NestedKeyOf<T>
  primaryHeader: string | ReactNode
  secondaryHeader: string | ReactNode
  accessorFn: (ctx: Readonly<CellContext<T, unknown>>) => {
    primary: string | ReactNode
    secondary: string | ReactNode
  }
  enableSorting?: boolean
  filterFn?: FilterFnOption<T>
}
export default function twoValuesColumn<T>({
  accessorKey,
  primaryHeader,
  secondaryHeader,
  accessorFn,
  enableSorting = false,
  filterFn,
}: Readonly<TwoValuesColumnProps<T>>): ColumnDef<T> {
  return {
    accessorKey,
    header: () => (
      <TwoValueTableElement
        primary={primaryHeader}
        secondary={secondaryHeader}
      />
    ),
    cell: (ctx: Readonly<CellContext<T, unknown>>) => {
      return <TwoValueTableElement {...accessorFn(ctx)} />
    },
    enableSorting,
    filterFn,
  }
}
