import { PropsWithChildren } from 'react'
import { PageBodyBgColor } from '@/pages/PageBodyBgColor.ts'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export interface PageBodyProps extends PropsWithChildren {
  bgColor: keyof typeof PageBodyBgColor
}

export default function PageBody({
  bgColor = 'gray',
  children,
}: Readonly<PageBodyProps>) {
  const style = twMerge(
    'tw-h-page tw-overflow-y-auto tw-p-8 2xl:tw-px-20',
    PageBodyBgColor[bgColor]
  )
  return <div className={style}>{children}</div>
}
