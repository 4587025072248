import {
  isServiceActivityClosed,
  serviceActivityStatusSort,
} from '@/helpers/ServiceActivityHelper.ts'
import moment from 'moment/moment'
import { ticketPrioritySort } from '@/components/table/sort/ticketPrioritySort.ts'
import { BaseServiceActivity } from '@/generated'

/**
 * The desired sort order for the initial sort of the asset service activity table cannot be defined as a
 * simple sort by columns. Additionally, some of the sort criteria are not desired to be sortable columns
 * in the table.
 */
export default function assetServiceActivitySort(
  a: BaseServiceActivity,
  b: BaseServiceActivity
) {
  // Closed tickets drop below active tickets
  const isAClosed = isServiceActivityClosed(a)
  const isBClosed = isServiceActivityClosed(b)
  if (isAClosed && !isBClosed) return 1
  if (!isAClosed && isBClosed) return -1
  // If both tickets are closed, sort by status, then by last updated
  if (isAClosed && isBClosed) {
    const statusCompare = serviceActivityStatusSort(a.status, b.status)
    if (statusCompare !== 0) return statusCompare
    const aUpdatedAt = moment(a.updatedAt)
    if (aUpdatedAt.isBefore(b.updatedAt)) return 1
    if (aUpdatedAt.isAfter(b.updatedAt)) return -1
    return 0
  }
  // If both tickets are active, sort by priority
  return ticketPrioritySort(a.priority ?? '', b.priority ?? '')
}
