import { useMemo } from 'react'
import { FilterDrawerFilterDef } from '@/components/core/drawers/filter/FiltersDrawerButton.tsx'
import { CommercialServiceActivity } from '@/generated'
import {
  assetManagerFilter,
  closedDateFilter,
  creationDateFilter,
  creationReasonFilter,
  pendingClientActionFilter,
  priorityFilter,
  scheduledDateFilter,
  serviceActivityPendingClientActionFilterDataAccessor,
  statusFilter,
  subStatusFilter,
  updatedDateFilter,
} from '@/features/serviceactivity/serviceActivityFilters.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'

export default function useCommercialServiceActivityTableFilters(
  pageName: ServiceActivityPageName
) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  const showAssetManagerColumn = useHasMultipleAssetManagers()
  return useMemo<
    Array<FilterDrawerFilterDef<CommercialServiceActivity>>
  >(() => {
    const filters: FilterDrawerFilterDef<CommercialServiceActivity>[] = []

    // Asset Manager (only for Service Activity tab when showAssetManagerColumn is true)
    if (
      pageName === ServiceActivityPageName.serviceActivity &&
      showAssetManagerColumn
    ) {
      filters.push(
        assetManagerFilter(
          'assetManagerName',
          rowData => rowData.assetManagerName
        )
      )
    }

    // Common filters for both tabs
    filters.push(
      creationDateFilter('createdAt'),
      priorityFilter(
        'priority',
        rowData => rowData.priority?.toString() ?? null
      ),
      creationReasonFilter(
        'creationReason',
        (rowData: CommercialServiceActivity) => rowData.creationReason
      ),
      statusFilter(
        'status',
        rowData => rowData.status,
        serviceCloudIntegrationEnabled
      )
    )

    // Filters specific to non-ServiceCloud integration
    if (!serviceCloudIntegrationEnabled) {
      filters.push(
        subStatusFilter(
          'subStatus',
          (rowData: CommercialServiceActivity) => rowData.subStatus
        ),
        scheduledDateFilter('scheduleDate')
      )
    }

    // Updated Date filter (common for both tabs)
    filters.push(updatedDateFilter('updatedAt'))

    // Filters specific to ServiceCloud integration
    if (serviceCloudIntegrationEnabled) {
      // Closed Date filter (only for Asset ServiceActivity tab)
      if (pageName === ServiceActivityPageName.assetServiceActivity) {
        filters.push(closedDateFilter('closedAt'))
      }

      // Pending Client Action filter
      filters.push(
        pendingClientActionFilter(
          'pendingClientAction',
          serviceActivityPendingClientActionFilterDataAccessor
        )
      )
    }

    return filters
  }, [pageName, showAssetManagerColumn, serviceCloudIntegrationEnabled])
}
