import { LinkProps } from 'react-router-dom'
import { StyledLink } from '@/components/link/StyledLink.tsx'
import { Icon } from '@iconify/react/offline'
import openInNewIcon from '@iconify/icons-material-symbols/open-in-new'

/**
 * Component that provides some sensible defaults for links to external domains.
 */
export default function ExternalLink({
  children,
  onClick,
  ...rest
}: Readonly<Omit<LinkProps, 'target'>>) {
  return (
    <StyledLink
      target="_blank"
      onClick={e => {
        // Since we're opening in a new tab, we typically want to stop event
        // propagation on the page we're leaving.
        e.stopPropagation()
        onClick?.(e)
      }}
      {...rest}
    >
      <div className="tw-inline-flex tw-items-center tw-gap-1">
        <span>{children}</span>
        <Icon icon={openInNewIcon} className="tw-size-4" />
      </div>
    </StyledLink>
  )
}
