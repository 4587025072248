import { useMemo } from 'react'
import { CsvColumnOption } from '@/components/export/ExportCsvButton.tsx'
import { ResidentialServiceActivity } from '@/generated'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'

export default function useResidentialServiceActivityCsvColumns(
  pageName?: ServiceActivityPageName
) {
  return useMemo<CsvColumnOption<ResidentialServiceActivity>[]>(() => {
    const columns: CsvColumnOption<ResidentialServiceActivity>[] = []

    if (pageName === ServiceActivityPageName.serviceActivity) {
      columns.push({ key: 'customerName', header: 'Customer Name' })
      columns.push({ key: 'clientAssetId', header: 'Client Asset ID' })
      columns.push({ key: 'assetId', header: 'Omnidian Asset ID' })
    }

    columns.push(
      { key: 'id', header: 'Ticket ID' },
      { key: 'createdAt', header: 'Ticket Created Date' },
      { key: 'updatedAt', header: 'Last Updated' },
      { key: 'priority', header: 'Ticket Priority' },
      { key: 'status', header: 'Ticket Status' },
      { key: 'ticketType', header: 'Ticket Type' },
      { key: 'subject', header: 'Ticket Subject' },
      { key: 'creationReason', header: 'Creation Reason' },
      {
        key: 'pendingClientAction',
        header: 'Pending Client Action',
        accessorFn: val => (val.pendingClientAction ? 'YES' : 'NO'),
      } as CsvColumnOption<ResidentialServiceActivity>
    )

    if (pageName === ServiceActivityPageName.serviceActivity) {
      columns.push({
        key: 'otherOpenServiceActivitiesCount',
        header: 'Count of Other Tickets Open for Asset',
      })
    }

    columns.push({ key: 'summary', header: 'Case Summary Notes' })

    return columns
  }, [pageName])
}
