import { AssetStage } from '@/generated'

const AssetStageLabelOverrides = new Map<AssetStage | null, string>([
  [AssetStage.MetadataVerification, 'Metadata Verification'],
])

export function getAssetStageLabel(
  assetStage: AssetStage | null
): string | null {
  return (
    AssetStageLabelOverrides.get(assetStage) ?? assetStage?.toString() ?? null
  )
}
