import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import ResidentialServiceActivityPage from '@/pages/serviceActivity/residential/ResidentialServiceActivityPage.tsx'
import CommercialServiceActivityPage from '@/pages/serviceActivity/commercial/CommercialServiceActivityPage.tsx'

export default function ServiceActivityPageWrapper() {
  const { isResidentialClient } = useFeatureFlags()

  if (isResidentialClient) {
    return <ResidentialServiceActivityPage />
  }

  return <CommercialServiceActivityPage />
}
