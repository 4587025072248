import { Dialog } from '@headlessui/react'
import type { GetAssetCaseResponse } from '@/generated'
import { Badge } from '@/components/core/badges/Badge.tsx'
import WorkOrdersPanel from './components/work-orders/WorkOrdersPanel.tsx'
import VerticalPropertyItem from './components/VerticalPropertyItem.tsx'
import { DateTimeFormat } from '@/helpers/NativeDateTimeHelpers.ts'
import {
  calcOpenDays,
  priorityToBadgeColor,
} from '@/helpers/ServiceActivityHelper.ts'
import { AssetDetailLink } from '@/pages/assetdetail/AssetDetailLink.tsx'

interface CaseViewModalContentProps {
  caseData: GetAssetCaseResponse
}

export default function CaseViewModalContent({
  caseData: {
    caseNumber,
    assetId,
    assetName,
    subject,
    priority,
    creationReason,
    status,
    workOrders,
    closedAt,
    createdAt,
    updatedAt,
  },
}: Readonly<CaseViewModalContentProps>) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-8">
      <div className="tw-flex tw-flex-col">
        <div className="tw-mb-1 tw-flex tw-items-center tw-gap-6 tw-text-sm">
          <Dialog.Title
            as="h2"
            className="tw-text-2xl tw-font-medium tw-text-gray-900"
          >
            Case: {subject}
          </Dialog.Title>

          <Badge color={priorityToBadgeColor(priority)} size="large">
            {priority}
          </Badge>

          <div className="tw-grow tw-text-right">
            <span className="tw-text-gray-500">
              {`Last Updated: ${DateTimeFormat.format(new Date(updatedAt))}`}
            </span>
          </div>
        </div>

        <p className="tw-text-sm">
          <AssetDetailLink assetId={assetId}>{assetName}</AssetDetailLink>
        </p>

        <div className="tw-flex tw-items-start tw-justify-between tw-gap-6 tw-py-10">
          <VerticalPropertyItem label="Case Number">
            {caseNumber}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Creation Reason">
            {creationReason}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Days Open">
            {calcOpenDays(createdAt, closedAt ?? undefined)}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Created Date">
            {DateTimeFormat.format(new Date(createdAt))}
          </VerticalPropertyItem>

          <VerticalPropertyItem label="Status">{status}</VerticalPropertyItem>
        </div>
      </div>

      {workOrders.length > 0 && <WorkOrdersPanel workOrders={workOrders} />}
    </div>
  )
}
