import { useResidentialServiceActivityByIdQuery } from '@/api/ServiceActivityQueries.ts'
import { Portal } from '@headlessui/react'
import { Drawer } from '@/components/core/drawers/Drawer.tsx'
import { SolarSpinner } from '@/components/core/spinner/SolarSpinner.tsx'
import Alert from '@/components/core/alerts/Alert.tsx'
import ServiceActivityDrawerContent from '@/features/serviceactivity/detailsDrawer/ServiceActivityDrawerContent.tsx'

interface ServiceActivityDrawerProps {
  serviceActivityId: string
  onClose: () => void
  onChangeServiceActivityId: (serviceActivityId: string) => void
}

export default function ServiceActivityDrawer({
  serviceActivityId,
  onClose,
  onChangeServiceActivityId,
}: Readonly<ServiceActivityDrawerProps>) {
  const query = useResidentialServiceActivityByIdQuery(serviceActivityId)
  return (
    <Portal>
      <Drawer
        show={true}
        onClose={onClose}
        size="large"
        data-testid="ServiceActivityDrawer"
      >
        <Drawer.Header onClose={onClose} />
        <Drawer.Body className="tw-px-12 tw-py-8">
          {query.isPending && (
            <div className="tw-flex tw-justify-center">
              <SolarSpinner height={72} width={72} />
            </div>
          )}
          {query.isError && (
            <Alert color="red">
              Something went wrong fetching the information for this service
              activity.
            </Alert>
          )}
          {query.isSuccess && (
            <ServiceActivityDrawerContent
              serviceActivity={query.data}
              onChangeServiceActivityId={onChangeServiceActivityId}
            />
          )}
        </Drawer.Body>
      </Drawer>
    </Portal>
  )
}
