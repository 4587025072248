import { Link, LinkProps } from 'react-router-dom'
import { twMerge } from '@/helpers/CustomTwMerge.ts'

export function StyledLink({
  className,
  children,
  ...rest
}: Readonly<LinkProps>) {
  return (
    <Link
      className={twMerge(
        'tw-font-bold tw-text-gray-900 hover:tw-text-blue-600 hover:tw-underline',
        className
      )}
      {...rest}
    >
      {children}
    </Link>
  )
}
