/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { Configuration, SpaControllerApi } from '@/generated'
import { useOktaAuth } from '@okta/okta-react'

export type LogLevel = 'ERROR' | 'WARN' | 'INFO' | 'DEBUG'

type LogEntry = {
  level: LogLevel
  data: object
}

const BUFFER_TIMEOUT_MS = 5_000
const BUFFER_SIZE = 10
let logBuffer: LogEntry[] = []
let timeoutId: any = null

export function flushLogEntries(accessToken?: string) {
  if (logBuffer.length === 0) return
  new SpaControllerApi(
    new Configuration({
      basePath: window.location.origin,
      accessToken: accessToken ?? undefined,
    })
  )
    .appendLog({
      entries: logBuffer,
    })
    .catch((e: unknown) => {
      console.error(e)
      return null
    })
  logBuffer = []
}

export function pushLogEntry(
  level: LogLevel,
  data: object,
  accessToken?: string
) {
  logBuffer.push({
    level,
    data: { ...data, _ts: new Date(), _path: window.location.pathname },
  })
  if (logBuffer.length >= BUFFER_SIZE) {
    flushLogEntries(accessToken)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      flushLogEntries(accessToken)
    }, BUFFER_TIMEOUT_MS)
  }
}

export function useLogger() {
  const { authState } = useOktaAuth()
  const accessToken = authState?.accessToken?.accessToken

  return useMemo(
    () => ({
      error: (data: object) => {
        pushLogEntry('ERROR', data, accessToken)
      },
      warn: (data: object) => {
        pushLogEntry('WARN', data, accessToken)
      },
      info: (data: object) => {
        pushLogEntry('INFO', data, accessToken)
      },
      debug: (data: object) => {
        pushLogEntry('DEBUG', data, accessToken)
      },
    }),
    [accessToken]
  )
}
