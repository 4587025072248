import { Maybe } from './TypeHelpers.ts'

export type NumberDisplayFormat = {
  positive: Intl.NumberFormat
  negative: Intl.NumberFormat
  whenMissing?: string
}

export function DefaultNumberFormat(options?: {
  locale?: string
  whenMissing?: string
}): NumberDisplayFormat {
  return {
    positive: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'auto',
    }),
    negative: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'exceptZero',
    }),
    whenMissing: options?.whenMissing,
  }
}

export function DecimalNumberFormat(options?: {
  locale?: string
  whenMissing?: string
}): NumberDisplayFormat {
  return {
    positive: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'auto',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    negative: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'exceptZero',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }),
    whenMissing: options?.whenMissing,
  }
}

export function PercentNumberFormat(options?: {
  locale?: string
  whenMissing?: string
}) {
  return {
    positive: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      style: 'percent',
      signDisplay: 'auto',
      maximumFractionDigits: 0,
    }),
    float: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      style: 'percent',
      signDisplay: 'auto',
      maximumFractionDigits: 2,
    }),
    negative: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      style: 'percent',
      signDisplay: 'exceptZero',
      maximumFractionDigits: 0,
    }),
    whenMissing: options?.whenMissing,
  }
}

export function IntegerNumberFormat(options?: {
  locale?: string
  whenMissing?: string
}): NumberDisplayFormat {
  return {
    positive: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'auto',
      maximumFractionDigits: 0,
    }),
    negative: Intl.NumberFormat(options?.locale ?? window.navigator.language, {
      signDisplay: 'exceptZero',
      maximumFractionDigits: 0,
    }),
    whenMissing: options?.whenMissing,
  }
}

export function formatNumber(
  value: Maybe<number>,
  format: NumberDisplayFormat = DefaultNumberFormat()
): Maybe<string> {
  if (value == null) return format.whenMissing
  if (value <= 0) return format.negative.format(value)
  return format.positive.format(value)
}

export function formatNumberToString(
  value: Maybe<number>,
  format: NumberDisplayFormat = DefaultNumberFormat()
): string {
  return formatNumber(value, format) ?? ''
}

export function formatNumberKw(value: Maybe<number>): Maybe<string> {
  const format = IntegerNumberFormat()
  if (value == null) return null
  if (value <= 0) return `${format.negative.format(value)} kW`
  return `${format.positive.format(value)} kW`
}
