import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { PropsWithChildren } from 'react'
import { StyledLink } from '@/components/link/StyledLink.tsx'

export interface AssetDetailLinkProps {
  assetId: number | null
  className?: string
}

export function AssetDetailLink({
  assetId,
  children,
  className,
}: PropsWithChildren<AssetDetailLinkProps>) {
  const { assetDetailEnabled } = useFeatureFlags()
  if (assetDetailEnabled && assetId !== null) {
    return (
      <StyledLink to={`/assets/${assetId.toString()}`} className={className}>
        {children}
      </StyledLink>
    )
  } else {
    return children
  }
}
