import { ReactNode } from 'react'
import { formatNumber } from '@/helpers/NumberFormats.ts'

type Props = {
  icon: ReactNode
  count: number
  description: string
}
export default function SnapshotSummary({
  icon,
  count,
  description,
}: Readonly<Props>) {
  return (
    <div className="tw-rounded-md tw-border tw-bg-gray-50 tw-p-4">
      <div className="tw-flex tw-gap-4">
        <div>{icon}</div>
        <div className="tw-flex-1">
          <div className="tw-text-2xl tw-font-bold">{formatNumber(count)}</div>
          <div className="tw-text-base tw-font-normal tw-text-gray-500">
            {description}
          </div>
        </div>
      </div>
    </div>
  )
}
