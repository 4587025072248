import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import ResidentialAssetServiceActivityTab from '@/pages/assetdetail/serviceActivity/residential/ResidentialAssetServiceActivityTab.tsx'
import CommercialAssetServiceActivityTab from '@/pages/assetdetail/serviceActivity/commercial/CommercialAssetServiceActivityTab.tsx'

export default function AssetServiceActivityTabWrapper() {
  const { isResidentialClient } = useFeatureFlags()

  if (isResidentialClient) {
    return <ResidentialAssetServiceActivityTab />
  }

  return <CommercialAssetServiceActivityTab />
}
