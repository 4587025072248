import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { useParams } from 'react-router-dom'
import { useAssetMetadataQuery } from '@/api/AssetQueries.ts'
import NotFoundPage from '@/pages/NotFoundPage.tsx'
import AssetDetailPageV2 from '@/pages/assetdetail/v2/AssetDetailPageV2.tsx'
import { AssetDetailPage } from '@/pages/assetdetail/AssetDetailPage.tsx'
import { AssetDetailContext } from '@/pages/assetdetail/AssetDetailContext.ts'
import { useMemo } from 'react'

export type AssetDetailParams = {
  assetId?: string
}

export default function AssetDetailPageWrapper() {
  const params = useParams<AssetDetailParams>()
  const assetId = parseInt(params.assetId ?? '', 10)
  const assetMetadataQuery = useAssetMetadataQuery(assetId)
  const { isResidentialClient } = useFeatureFlags()
  const assetDetailContext = useMemo(
    () => ({
      assetId,
    }),
    [assetId]
  )

  if (isNaN(assetId)) {
    return <NotFoundPage />
  }
  if (assetMetadataQuery.isError) {
    // This will be handled by the global error handler modal. Could use some more nuance here
    // (e.g. identifying a 404 vs. a 500) to render a dedicated UI.
    // https://omnidian.atlassian.net/browse/HYP-2510 for finding a more holistic solution for exposing the status
    // code from the error and develop a holistic solution that could be applied to any page.
    throw assetMetadataQuery.error
  }
  return (
    <AssetDetailContext.Provider value={assetDetailContext}>
      {isResidentialClient ? <AssetDetailPageV2 /> : <AssetDetailPage />}
    </AssetDetailContext.Provider>
  )
}
