import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import textColumn from '@/components/core/table/columns/textColumn.tsx'
import { TicketPriorityCell } from '@/components/table/cells/TicketPriorityCell.tsx'
import { ticketPriorityTableRowSort } from '@/components/table/sort/ticketPrioritySort.ts'
import { CommercialServiceActivity } from '@/generated'
import { WorkOrdersCountCell } from '@/components/table/cells/WorkOrdersCountCell.tsx'
import { dateRangeFilterFn } from '@/components/core/table/filters/dateRangeFilterFn.ts'
import { DateTimeCell } from '@/components/core/table/cells/DateTimeCell.tsx'
import { facetFilterFn } from '@/components/core/table/filters/facetFilterFn.ts'
import { ServiceActivityStatusCell } from '@/components/table/cells/ServiceActivityStatusCell.tsx'
import { TicketStatusCell } from '@/components/table/cells/TicketStatusCell.tsx'
import { DateCell } from '@/components/core/table/cells/DateCell.tsx'
import { pendingClientActionSortFn } from '@/components/table/sort/pendingClientActionSort.ts'
import { serviceActivityPendingClientActionFilterDataAccessor } from '@/features/serviceactivity/serviceActivityFilters.ts'
import PendingClientActionCell from '@/components/table/cells/PendingClientActionCell.tsx'
import AssetNameCell from '@/pages/serviceActivity/commercial/tableComponents/AssetNameCell.tsx'
import { useHasMultipleAssetManagers } from '@/api/hooks/assetManagerHooks.ts'
import { useFeatureFlags } from '@/helpers/FeatureFlags.tsx'
import { ServiceActivityPageName } from '@/pages/serviceActivity/commercial/types.ts'

export default function useCommercialServiceActivityTableColumns(
  pageName: ServiceActivityPageName
) {
  const { serviceCloudIntegrationEnabled } = useFeatureFlags()
  const showAssetManagerColumn = useHasMultipleAssetManagers()
  return useMemo<ColumnDef<CommercialServiceActivity>[]>(() => {
    const columns: ColumnDef<CommercialServiceActivity>[] = []

    // Asset Manager (only for ServiceActivity view)
    if (
      pageName === ServiceActivityPageName.serviceActivity &&
      showAssetManagerColumn
    ) {
      columns.push(
        textColumn({
          key: 'assetManagerName',
          header: 'Asset Manager',
          mode: 'filterable-facets',
          disableColumnFilter: true,
        })
      )
    }

    // Asset Name (only Asset ServiceActivity view)
    if (pageName === ServiceActivityPageName.serviceActivity) {
      columns.push(
        textColumn({
          key: 'assetName',
          header: 'Asset Name',
          cell: AssetNameCell,
        })
      )
    }

    // Case Number / Ticket ID and Work Orders
    if (serviceCloudIntegrationEnabled) {
      columns.push(
        textColumn({
          key: 'caseNumber',
          header: 'Case Number',
        }),
        {
          header: 'Work Orders',
          accessorKey: 'workOrdersCount',
          enableSorting: false,
          cell: WorkOrdersCountCell,
        }
      )
    } else {
      columns.push(
        textColumn({
          key: 'id',
          header: 'Ticket ID',
        })
      )
    }

    // Common columns for all views
    columns.push(
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        enableSorting: true,
        header: 'Creation Date',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      },
      {
        accessorKey: 'priority',
        header: 'Priority',
        enableSorting: true,
        sortingFn: ticketPriorityTableRowSort(row =>
          row.original.priority?.toString()
        ),
        filterFn: facetFilterFn,
        cell: TicketPriorityCell,
      },
      textColumn({
        key: 'subject',
        header: 'Subject',
      }),
      textColumn({
        key: 'creationReason',
        header: 'Creation Reason',
        mode: 'filterable-facets',
        disableColumnFilter: true,
      }),
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        cell: ServiceActivityStatusCell,
        filterFn: facetFilterFn,
      }
    )

    // Columns specific to non-ServiceCloud integration
    if (!serviceCloudIntegrationEnabled) {
      columns.push(
        textColumn({
          key: 'subStatus',
          header: 'Progress',
          cell: TicketStatusCell,
          mode: 'filterable-facets',
          disableColumnFilter: true,
        }),
        {
          id: 'scheduleDate',
          accessorKey: 'scheduleDate',
          enableSorting: true,
          header: 'Schedule Date',
          filterFn: dateRangeFilterFn,
          cell: DateCell,
        }
      )
    }

    // Last Updated (common for all views)
    columns.push({
      id: 'updatedAt',
      accessorKey: 'updatedAt',
      enableSorting: true,
      header: 'Last Updated',
      filterFn: dateRangeFilterFn,
      cell: DateTimeCell,
    })

    // Closed Date (only for Asset ServiceActivity view with ServiceCloud integration)
    if (
      pageName === ServiceActivityPageName.assetServiceActivity &&
      serviceCloudIntegrationEnabled
    ) {
      columns.push({
        id: 'closedAt',
        accessorFn: row => row.closedAt ?? undefined,
        enableSorting: true,
        header: 'Closed Date',
        filterFn: dateRangeFilterFn,
        cell: DateTimeCell,
      })
    }

    // Pending Client Action (only for ServiceCloud integration)
    if (serviceCloudIntegrationEnabled) {
      columns.push({
        id: 'pendingClientAction',
        accessorKey: 'pendingClientAction',
        enableSorting: true,
        sortingFn: pendingClientActionSortFn,
        header: 'Pending Client Action',
        filterFn: facetFilterFn,
        accessorFn: serviceActivityPendingClientActionFilterDataAccessor,
        cell: PendingClientActionCell,
      } as ColumnDef<CommercialServiceActivity>)
    }

    return columns
  }, [pageName, showAssetManagerColumn, serviceCloudIntegrationEnabled])
}
