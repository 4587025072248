export enum PendingActionText {
  yes = 'Action Required',
  no = 'No Action Required',
}

export enum ServiceActivityPageName {
  portfolioOverview = 'Portfolio Overview',
  assetServiceActivity = 'Asset Service Activity',
  serviceActivity = 'Service Activity',
}
