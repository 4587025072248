import { Tooltip } from '@/components/core/tooltip/Tooltip.tsx'
import { Icon } from '@iconify/react/offline'
import infoIcon from '@iconify/icons-material-symbols/info-outline'
import SnapshotValue from '@/pages/serviceActivity/residential/snapShots/components/SnapshotValue.tsx'
import { ReactElement } from 'react'

type Props = {
  label: string
  description?: ReactElement
  children:
    | ReactElement<typeof SnapshotValue>
    | Array<ReactElement<typeof SnapshotValue>>
}

export default function SnapshotLine({
  label,
  description,
  children,
}: Readonly<Props>) {
  return (
    <div
      data-testid="summary-line"
      className={`tw-flex tw-justify-between tw-text-sm tw-font-normal tw-text-gray-700`}
    >
      <div className="tw-w-3/4 tw-text-wrap">
        <div className="tw-flex tw-items-center tw-gap-2">
          {description && (
            <Tooltip
              content={
                <div className="tw-max-w-48 tw-text-left">{description}</div>
              }
            >
              <Icon
                icon={infoIcon}
                data-testid="descriptionIcon"
                className="tw-text-gray-500"
                width={16}
                height={16}
              />
            </Tooltip>
          )}
          {label}
        </div>
      </div>
      <div className="tw-flex tw-items-center tw-gap-2">{children}</div>
    </div>
  )
}
