import { useFeatureFlagQuery } from '@/api/SpaQueries.ts'
import { FeatureFlags } from '@/generated'

export const AllDisabledFlags = {
  assetDetailEnabled: false,
  assetPerformanceEnabled: false,
  portfolioOverviewEnabled: false,
  serviceActivityEnabled: false,
  serviceCloudIntegrationEnabled: false,
  isResidentialClient: false,
}

/**
 * A very quick-and-dirty implementation of feature flags. We've been promised more engineering time
 * to implement something more robust in the future. For now, without a set of long-term requirements,
 * we're doing something simple.
 */
export function useFeatureFlags(): FeatureFlags {
  const featureFlagsResult = useFeatureFlagQuery()

  return featureFlagsResult.data ?? AllDisabledFlags
}
